import React, { useEffect, useState, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Typography,
    Switch,
    FormControlLabel
} from '@mui/material';
import Logo from '../wayfaster-logo.svg'
//@TODO make sure webcam isn't being used if it's not required - chrome says its still active when is disabled


interface WebcamPreviewProps {
    isMobile: boolean;
    isWebcamRequired: boolean;
    open: boolean;
    onClose: () => void;
    onDevicesSelected: (videoDeviceId: string, audioDeviceId: string) => void;
}

const WebcamPreviewModal: React.FC<WebcamPreviewProps> = ({
    isMobile,
    isWebcamRequired,
    open,
    onClose,
    onDevicesSelected
}) => {
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedVideo, setSelectedVideo] = useState<string>('');
    const [selectedAudio, setSelectedAudio] = useState<string>('');
    const [useWebcam, setUseWebcam] = useState<boolean>(true);
    const videoRef = useRef<HTMLVideoElement>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const analyzerRef = useRef<AnalyserNode | null>(null);
    const animationFrameRef = useRef<number>();
    const [webcamActive, setWebcamActive] = useState<boolean>(false);
    const [micActive, setMicActive] = useState<boolean>(false);
    const [inputsEnabled, setInputsEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (open && useWebcam) {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    const videos = devices.filter(device => device.kind === 'videoinput');
                    const audios = devices.filter(device => device.kind === 'audioinput');
                    setVideoDevices(videos);
                    setAudioDevices(audios);

                    if (videos.length) setSelectedVideo(videos[0].deviceId);
                    if (audios.length) setSelectedAudio(audios[0].deviceId);
                })
                .catch(err => console.error('Error enumerating devices:', err));
        }
    }, [open, useWebcam, inputsEnabled]);

    useEffect(() => {
        if (selectedVideo && videoRef.current && useWebcam) {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }

            navigator.mediaDevices.getUserMedia({
                video: { deviceId: selectedVideo },
                audio: false
            })
                .then(stream => {
                    streamRef.current = stream;
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                    }
                    setWebcamActive(true);
                    if(!inputsEnabled) {
                        setInputsEnabled(true);
                    }
                })
                .catch(err => {
                    console.error('Error accessing media devices:', err);
                    setWebcamActive(false);
                    if(!inputsEnabled) {
                        setInputsEnabled(false);
                    }
                });
        }

        return () => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
        };
    }, [selectedVideo, useWebcam, webcamActive, inputsEnabled]);

    useEffect(() => {
        if (selectedAudio) {
            navigator.mediaDevices.getUserMedia({
                audio: { deviceId: selectedAudio }
            })
                .then(stream => {
                    const audioContext = new AudioContext();
                    const source = audioContext.createMediaStreamSource(stream);
                    const analyzer = audioContext.createAnalyser();
                    analyzer.fftSize = 512;
                    analyzer.smoothingTimeConstant = 0.8;
                    source.connect(analyzer);
                    analyzerRef.current = analyzer;
                    setMicActive(true);

                    const drawAudioLevel = () => {
                        if (!canvasRef.current || !analyzer) return;

                        const canvas = canvasRef.current;
                        const ctx = canvas.getContext('2d');
                        if (!ctx) return;

                        const dataArray = new Uint8Array(analyzer.frequencyBinCount);
                        analyzer.getByteFrequencyData(dataArray);

                        // Clear canvas with a slight fade effect
                        // ctx.fillStyle = 'rgba(245, 245, 245, 0.2)';
                        // ctx.fillRect(0, 0, canvas.width, canvas.height);

                        ctx.clearRect(0, 0, canvas.width, canvas.height);

                        // Set up visualization parameters
                        const centerY = canvas.height / 2;
                        const barWidth = 4;
                        const barSpacing = 2;
                        const maxBarHeight = canvas.height * 0.6;
                        const bars = Math.min(dataArray.length, Math.floor(canvas.width / (barWidth + barSpacing)));
                        const startX = (canvas.width - (bars * (barWidth + barSpacing))) / 2;

                        // Draw each bar
                        for (let i = 0; i < bars; i++) {
                            const value = dataArray[i];
                            const barHeight = (value / 255) * maxBarHeight;
                            const x = startX + i * (barWidth + barSpacing);

                            // Create gradient for each bar
                            const gradient = ctx.createLinearGradient(0, centerY - barHeight / 2, 0, centerY + barHeight / 2);
                            //gradient.addColorStop(0, '#F4E132');
                            gradient.addColorStop(0, '#FFFFFF');
                            gradient.addColorStop(1, '#FFFFFF');

                            // Draw bar with rounded corners
                            ctx.beginPath();
                            ctx.roundRect(
                                x,
                                centerY - barHeight / 2,
                                barWidth,
                                barHeight,
                                2
                            );
                            ctx.fillStyle = gradient;
                            ctx.fill();
                        }

                        animationFrameRef.current = requestAnimationFrame(drawAudioLevel);
                    };

                    drawAudioLevel();
                })
                .catch(err => {
                    console.error('Error accessing microphone:', err);
                    setMicActive(false);
                });
        }

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [selectedAudio, micActive]);

    useEffect(() => {
        if (!useWebcam && streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }
    }, [useWebcam]);

    const handleConfirm = () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }
        onDevicesSelected(useWebcam ? selectedVideo : '', selectedAudio);
        onClose();
    };

    const handleEnableCameraAndMic = () => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then(function (stream) {
                setInputsEnabled(true);
            })
            .catch(function (err) {
                console.log('Inputs permission denied');
            });        
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            maxWidth={false}
            fullWidth
            fullScreen
            PaperProps={{
                sx: {
                    borderRadius: 0,
                    overflow: 'auto',
                    height: '100vh',
                    maxHeight: '100vh'
                }
            }}
        >
            <DialogTitle sx={{
                fontFamily: 'PPNeueMontreal',
                marginBottom: { xs: '16px', md: '24px' },
                padding: '12px',
                borderBottom: '1px solid #E9E9E9',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img src={Logo} alt="Wayfaster Logo" />
                {!isWebcamRequired && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={useWebcam}
                                onChange={(e) => setUseWebcam(e.target.checked)}
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: '#F4E132',
                                        '&:hover': {
                                            backgroundColor: 'rgba(244, 225, 50, 0.04)',
                                        },
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: '#F4E132',
                                    },
                                }}
                            />
                        }
                        label="Enable webcam"
                        sx={{ m: 0 }}
                    />
                )}
            </DialogTitle>
            <Box sx={{
                display: 'flex',
                gap: 5,
                flexDirection: { xs: 'column', md: 'row' },
                minHeight: { xs: 'min-content', md: 'auto' },
                paddingBottom: { xs: '24px', md: '0' }
            }}>
                <DialogContent sx={{ padding: '24px' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                        maxWidth: '100%',
                        margin: '0',
                        height: '80%',
                        //paddingTop: '5%',
                        paddingLeft: isMobile ? '0px' : '32px'
                    }}>
                        {useWebcam ? (
                            <>
                                {/* Title Row */}
                                <Typography
                                    sx={{
                                        color: 'black',
                                        fontSize: { xs: '16px', md: '20px' },
                                        fontFamily: 'PPNeueMontreal',
                                        fontWeight: 600,
                                    }}
                                >
                                    Make sure the microphone and camera are working properly
                                </Typography>

                                {/* Video and Audio Preview Container */}
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}>
                                    {/* Video Container with Audio Overlay */}
                                    <Box
                                        sx={{
                                        position: 'relative',  // This is needed just for overlay positioning
                                        width: '100%',
                                        height: { xs: '300px', md: '400px', lg: '500px', xl: '650px' },
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                    }}>
                                        <video
                                            ref={videoRef}
                                            autoPlay
                                            playsInline
                                            className="video-feed-mirrored"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                backgroundColor: '#f5f5f5',
                                            }}
                                        />
                                        {/* Audio Visualization Overlay */}
                                        <Box sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: isMobile ? '50%' : '20px',
                                            transform: isMobile ? 'translateX(-50%)' : 'translateX(0%)',
                                            width: '250px',
                                            height: '150px',
                                            borderRadius: '8px',
                                            overflow: 'hidden',
                                            //backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                        }}>
                                            <canvas
                                                ref={canvasRef}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            //width={300}
                                            //height={150}
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                {/* Device Controls Row */}
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    flexDirection: { xs: 'column', md: 'row' }
                                }}>
                                    <FormControl fullWidth>
                                        <InputLabel sx={{
                                            color: '#000',
                                            '&.Mui-focused': { color: '#000' }
                                        }}>Camera</InputLabel>
                                        <Select
                                            value={selectedVideo}
                                            onChange={(e) => setSelectedVideo(e.target.value)}
                                            label="Camera"
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#E9E9E9' },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#000' },
                                                '& .Mui-selected': { color: '#666666' }
                                            }}
                                        >
                                            {videoDevices.map(device => (
                                                <MenuItem key={device.deviceId} value={device.deviceId}>
                                                    {device.label || `Camera ${device.deviceId.slice(0, 5)}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel sx={{
                                            color: '#000',
                                            '&.Mui-focused': { color: '#000' }
                                        }}>Microphone</InputLabel>
                                        <Select
                                            value={selectedAudio}
                                            onChange={(e) => setSelectedAudio(e.target.value)}
                                            label="Microphone"
                                            sx={{
                                                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#E9E9E9' },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#000' },
                                                '& .Mui-selected': { color: '#666666' }
                                            }}
                                        >
                                            {audioDevices.map(device => (
                                                <MenuItem key={device.deviceId} value={device.deviceId}>
                                                    {device.label || `Microphone ${device.deviceId.slice(0, 5)}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </>
                        ) : (
                            <Box sx={{
                                width: '100%',
                                height: '300px',
                                marginBottom: '16px'
                            }}>
                                <canvas
                                    ref={canvasRef}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '8px',
                                    }}
                                    width={300}
                                    height={300}
                                />
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <Box sx={{
                    display: 'flex',
                    width: isMobile ? '90%' : '40%',
                    alignContent: "center",
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: { xs: '0 24px 24px', md: '0' },
                }}>
                    <DialogTitle sx={{
                        fontFamily: 'PPNeueMontreal',
                        fontWeight: 600,
                        fontSize: { xs: '16px', md: '20px' },
                        padding: { xs: '0 0 0', md: '0' },
                        width: '100%',
                        textAlign: 'center',
                    }}>
                        Ready for Interview?
                    </DialogTitle>

                    <DialogTitle sx={{
                        fontFamily: 'PPNeueMontreal',
                        fontWeight: 600,
                        width: isMobile ? '100%' : '70%',
                        fontSize: { xs: '12px', md: '16px' },
                        color: 'gray',
                        paddingBottom: '24px',
                        textAlign: 'center',
                    }}>
                        Once you have chosen the right camera and microphone settings, you can join the meeting
                    </DialogTitle>

                    <DialogActions sx={{
                        padding: '0px',
                        width: '100%',
                        justifyContent: 'center'
                    }}>
                        {!inputsEnabled && (
                            <Button
                                onClick={handleEnableCameraAndMic}
                                variant="contained"
                                fullWidth
                                sx={{
                                    maxWidth: isMobile ? '100%' : '270px',
                                    background: '#000',
                                    color: 'white', 
                                    borderRadius: '12px',
                                    fontFamily: 'PPNeueMontreal',
                                    paddingY: '10px',
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    '&:hover': {
                                        background: '#000',
                                    }
                                }}
                            >
                                Enable Camera and Mic
                            </Button>
                        )}
                        {inputsEnabled && (
                            <Button
                                onClick={handleConfirm}
                                variant="contained"
                                disabled={!webcamActive || !micActive}
                                fullWidth
                                sx={{
                                    maxWidth: isMobile ? '100%' : '170px',
                                    background: '#000',
                                    color: 'white',
                                    borderRadius: '12px',
                                    fontFamily: 'PPNeueMontreal',
                                    paddingY: '10px',
                                    fontSize: '16px',
                                    textTransform: 'none',
                                    '&:hover': {
                                        background: '#000',
                                    }
                                }}
                                autoFocus
                            >
                                Confirm & continue
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </Box>
        </Dialog>
    );
};

export default WebcamPreviewModal;
